export enum DocumentTags {
  LANGUAGE_TEST = 'LANGUAGE_TEST',
  LANGUAGE_TEST_IELTS = 'LANGUAGE_TEST_IELTS',
  LANGUAGE_TEST_PTE = 'LANGUAGE_TEST_PTE',
  LANGUAGE_TEST_TOEFL = 'LANGUAGE_TEST_TOEFL',
  LANGUAGE_TEST_OTHER = 'LANGUAGE_TEST_OTHER',
  TRANSCRIPTS = 'TRANSCRIPTS',
  CERTIFICATE = 'CERTIFICATE',
  PASSPORT = 'PASSPORT',
  STUDY_PERMIT = 'STUDY_PERMIT',
  WORK_PERMIT = 'WORK_PERMIT',
  VISITOR_TOURIST_VISA = 'VISITOR_TOURIST_VISA',
  DESTINATION_COUNTRY_STATUS = 'DESTINATION_COUNTRY_STATUS',
  STATEMENT_OF_PURPOSE = 'STATEMENT_OF_PURPOSE',
  LETTER_OF_RECOMMENDATION = 'LETTER_OF_RECOMMENDATION',
  CONSENT_FORM = 'CONSENT_FORM',
  GMAT_SCORE = 'GMAT_SCORE',
  GRE_SCORE = 'GRE_SCORE',
  RESUME = 'RESUME',
  EMPLOYMENT_LETTER = 'EMPLOYMENT_LETTER',
  VOLUNTEER_LETTER = 'VOLUNTEER_LETTER',
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
  PRE_OFFER_LETTER = 'PRE_OFFER_LETTER',
  DECLINED_LETTER = 'DECLINED_LETTER',
}

export enum DecisionTypeEnum {
  PRE_OFFER = 'PRE_OFFER',
  FINAL_OFFER = 'FINAL_OFFER',
  WAITLIST = 'WAITLIST',
}

export enum StudentResponseEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DECLINED = 'DECLINED',
}

export type AdditionalDocumentTags = Extract<
  DocumentTags,
  | DocumentTags.STATEMENT_OF_PURPOSE
  | DocumentTags.LETTER_OF_RECOMMENDATION
  | DocumentTags.CONSENT_FORM
  | DocumentTags.GMAT_SCORE
  | DocumentTags.GRE_SCORE
  | DocumentTags.RESUME
  | DocumentTags.EMPLOYMENT_LETTER
  | DocumentTags.VOLUNTEER_LETTER
  | DocumentTags.ADDITIONAL_DOCUMENT
>

export enum DestinationCountryStatus {
  NO_STATUS = 'NO_STATUS',
  WORK_PERMIT = 'WORK_PERMIT',
  VISITOR = 'VISITOR',
  STUDY_PERMIT = 'STUDY_PERMIT',
  OTHER = 'OTHER',
}

export enum LanguageExam {
  IELTS = 'IELTS',
  PTE = 'PTE',
  TOEFL = 'TOEFL',
  OTHER = 'OTHER',
}

export enum LanguageExamAvailability {
  HAVE = 'HAVE',
  DONT_NEED = 'DONT_NEED',
  WILL_HAVE = 'WILL_HAVE',
}

export enum RelationshipType {
  PARENT = 'PARENT',
  GUARDIAN = 'GUARDIAN',
  FRIEND = 'FRIEND',
  SPOUSE = 'SPOUSE',
  RELATIVE = 'RELATIVE',
  OTHER = 'OTHER',
}

export enum EducationLevel {
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  UNDERGRAD = 'UNDERGRAD',
  GRADUATE = 'GRADUATE',
  PHD = 'PHD',
}

export enum EducationLevelLabels {
  PRIMARY_SCHOOL = 'Elementary / Primary',
  MIDDLE_SCHOOL = 'Junior Secondary / Middle School',
  HIGH_SCHOOL = 'Senior Secondary / High School',
  UNDERGRAD = 'Undergraduate',
  GRADUATE = 'Graduate',
  PHD = 'Doctorate',
}
