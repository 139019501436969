import { Card, Flex, Heading, Text } from '@applyboard/crystal-ui'
import { useQueryClient } from '@tanstack/react-query'
import { FileData } from 'applications-types-lib'

import { VersionedApplicationSanitizedWithFiles } from '@backend/sanitize/application'

import {
  RawApplicationsResponse,
  useUpdateDecisionLetters,
} from '../../../hooks/useUpdateDecisionLetters'
import { RawApplicationAggregationResponse, useGetApplication } from '../../../hooks'
import { DecisionTypeEnum, DocumentTags, StudentResponseEnum } from '../../../utils/enums'
import { getFilesOfType } from '../ApplicationForms/utils'

import { ApplicationDetailCard } from './ApplicationDetailCard'
import { ApplicationDetailDecisionLetterHeading } from './ApplicationDetailDecisionLetterHeading'
import { ApplicationDetailAdditionalDocuments } from './ApplicationDetailAdditionalDocuments'
import { ApplicationDetailCardRejectDialogForm } from './ApplicationDetailCard/ApplicationDetailCardActions/ApplicationDetailCardRejectDialog'

type ApplicationDetailsProps = Readonly<{
  applicationId: string
  onOfferConditionsClick: () => void
}>

function getTitleByType(type: DecisionTypeEnum): string {
  switch (type) {
    case DecisionTypeEnum.PRE_OFFER:
      return 'Pre-Offer'
    case DecisionTypeEnum.FINAL_OFFER:
      return 'Final Offer'
    case DecisionTypeEnum.WAITLIST:
      return 'Waitlist'
    default:
      return '-'
  }
}

export function ApplicationDetails(props: ApplicationDetailsProps) {
  const queryClient = useQueryClient()
  const { school, application } = useGetApplication({
    id: props.applicationId,
  })
  const { updateLettersDecision, isLoadingUpdateLettersDecision } = useUpdateDecisionLetters()

  const studentDecisions = application?.attributes?.studentDecisions

  const onSuccess = (onSuccessProps: () => void) => (response: RawApplicationsResponse) => {
    queryClient.setQueryData(
      ['applications', response.data.id],
      (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
        return {
          data: {
            ...oldData.data,
            attributes: {
              ...oldData.data.attributes,
              ...response.data.attributes,
            },
          },
        }
      },
    )
    onSuccessProps()
  }

  const onSubmitAcceptOffer =
    (studentDecisionId: string, letterType: DecisionTypeEnum) =>
    ({
      onError,
      onSuccess: onSuccessProps,
    }: {
      onSuccess: () => void
      onError: (err: Error) => void
    }): void => {
      updateLettersDecision(
        {
          applicationId: props.applicationId,
          studentDecisions: {
            [studentDecisionId]: {
              studentResponse: StudentResponseEnum.ACCEPTED,
              letterType,
            },
          },
        },
        {
          onSuccess: onSuccess(onSuccessProps),
          onError,
        },
      )
    }

  const onSubmitRejectOffer =
    (studentDecisionId: string, letterType: DecisionTypeEnum) =>
    ({
      data,
      onError,
      onSuccess: onSuccessProps,
    }: {
      onSuccess: () => void
      onError: (err: Error) => void
      data: ApplicationDetailCardRejectDialogForm
    }): void => {
      updateLettersDecision(
        {
          applicationId: props.applicationId,
          studentDecisions: {
            [studentDecisionId]: {
              studentResponse: StudentResponseEnum.REJECTED,
              letterType,
              reason: data.reason,
            },
          },
        },
        {
          onSuccess: onSuccess(onSuccessProps),
          onError,
        },
      )
    }

  const getFile = (sectionReference: string): { name: string; url: string } => {
    const metaFiles = (application as VersionedApplicationSanitizedWithFiles)?.meta?.files
    const filesPreOffer = getFilesOfType(
      [DocumentTags.PRE_OFFER_LETTER],
      application?.attributes?.files as FileData,
    )

    const fileKey: string | undefined = Object.keys(filesPreOffer).find(
      key => filesPreOffer[key]?.sectionReference === sectionReference,
    )

    if (fileKey) {
      return {
        name: filesPreOffer[fileKey]?.fileName || '-',
        url: metaFiles[sectionReference]?.download?.url || '#',
      }
    }

    return {
      name: '-',
      url: '#',
    }
  }

  return (
    <Flex direction="column" gap={6}>
      <Card>
        <Card.Header>
          <ApplicationDetailDecisionLetterHeading />
        </Card.Header>
        <Card.Divider mt={2} mb={3} />
        <Flex direction="column" gap={6}>
          {studentDecisions
            ? Object.keys(studentDecisions).map((key: string) => (
                <ApplicationDetailCard
                  key={`studentDecision-${key}`}
                  title={getTitleByType(studentDecisions[key].type)}
                  status={studentDecisions[key].studentResponse}
                  issuedOn={new Date(studentDecisions[key].decidedOnDate)}
                  file={getFile(key)}
                  rejectDialog={
                    studentDecisions[key]?.type === DecisionTypeEnum.PRE_OFFER &&
                    studentDecisions[key]?.studentResponse === 'PENDING'
                      ? {
                          onSubmit: onSubmitRejectOffer(key, studentDecisions[key].type),
                        }
                      : undefined
                  }
                  onOfferConditionsClick={
                    studentDecisions[key].type === DecisionTypeEnum.PRE_OFFER &&
                    studentDecisions[key].studentResponse === 'PENDING'
                      ? props.onOfferConditionsClick
                      : undefined
                  }
                  helperText={studentDecisions[key].reason}
                  loading={isLoadingUpdateLettersDecision}
                  acceptDialog={
                    studentDecisions[key]?.type === DecisionTypeEnum.PRE_OFFER &&
                    studentDecisions[key]?.studentResponse === 'PENDING'
                      ? {
                          programName:
                            application?.attributes?.programSelected?.program?.name ?? '',
                          campusName: application?.attributes?.programSelected?.campus?.name ?? '',
                          intakeTermName:
                            application?.attributes?.programSelected?.programIntakeTerm?.name ?? '',
                          onSubmit: onSubmitAcceptOffer(key, studentDecisions[key].type),
                        }
                      : undefined
                  }
                />
              ))
            : null}
        </Flex>
      </Card>

      {school?.additionalDocuments.length ? (
        <Card>
          <Card.Header>
            <Flex direction="column" gap={2}>
              <Flex.Item>
                <Heading level={2} variant="titleS">
                  Additional Documents Received
                </Heading>
              </Flex.Item>
              <Text contrast="mid">
                The following documents have been shared by the school. Please download them, as
                they contain important information that will assist you in the next steps of the
                process.
              </Text>
            </Flex>
          </Card.Header>
          <Card.Divider mt={2} mb={3} />
          <ApplicationDetailAdditionalDocuments files={school.additionalDocuments} />
        </Card>
      ) : null}
    </Flex>
  )
}
