import { Card, Divider, Flex } from '@applyboard/crystal-ui'

import { ApplicationDetailsFile, ApplicationDetailsStatus, ApplicationDetailsIntent } from './types'
import { ApplicationDetailCardActions } from './ApplicationDetailCardActions'
import { ApplicationDetailCardDescription } from './ApplicationDetailCardDescription'
import { ApplicationDetailFile } from '../ApplicationDetailFile'
import { ApplicationDetailCardIssued } from './ApplicationDetailCardIssued'
import { ApplicationDetailCardTitle } from './ApplicationDetailCardTitle'
import { ApplicationDetailCardHelper } from './ApplicationDetailCardHelper'
import { ApplicationDetailCardRejectDialogProps } from './ApplicationDetailCardActions/ApplicationDetailCardRejectDialog'
import { ApplicationDetailCardAcceptDialogProps } from './ApplicationDetailCardActions/ApplicationDetailCardAcceptDialog'

type ApplicationDetailCardProps = Readonly<{
  title: string
  issuedOn: Date
  file: ApplicationDetailsFile
  status?: ApplicationDetailsStatus
  statusChangedOn?: Date
  rejectDialog?: ApplicationDetailCardRejectDialogProps
  onOfferConditionsClick?: () => void
  helperText?: string
  acceptDialog?: ApplicationDetailCardAcceptDialogProps
  loading?: boolean
}>

function getIntentByStatus(status: ApplicationDetailsStatus): ApplicationDetailsIntent {
  switch (status) {
    case 'DECLINED':
      return 'negative'
    case 'REJECTED':
      return 'negative'
    case 'ACCEPTED':
      return 'positive'
    default:
      return 'secondary'
  }
}

export function ApplicationDetailCard(props: ApplicationDetailCardProps) {
  const intent = props.status ? getIntentByStatus(props.status) : 'secondary'

  return (
    <Card>
      <Flex direction="column">
        <Flex
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          gap={4}
        >
          <Flex
            gap={3}
            align="center"
            justify={{
              xs: 'between',
              lg: 'start',
            }}
            grow={1}
            wrap
          >
            <ApplicationDetailCardTitle intent={intent} title={props.title} />
            <ApplicationDetailCardIssued intent={intent} issuedOn={props.issuedOn} />
          </Flex>

          <Flex.Item basis={{ xs: '100%', lg: '43%' }}>
            <ApplicationDetailFile url={props.file.url} name={props.file.name} />
          </Flex.Item>
        </Flex>

        <Divider />

        <Flex
          gap={{ lg: 4 }}
          direction={{ xs: 'column', lg: 'row' }}
          align={{ lg: 'center' }}
          justify={{ lg: props.status ? 'between' : 'end' }}
          pb={{ lg: props.status ? 0 : 4 }}
        >
          {props.status ? (
            <Flex.Item basis={{ lg: '53%' }}>
              <ApplicationDetailCardDescription
                status={props.status}
                statusChangedOn={props.statusChangedOn}
              />
            </Flex.Item>
          ) : null}
          {(props.acceptDialog && props.rejectDialog) || props.onOfferConditionsClick ? (
            <Flex
              direction={{ xs: 'column', lg: 'row' }}
              justify="end"
              gap={{ xs: 4, lg: 2 }}
              pb={{ xs: 4, lg: 0 }}
            >
              <ApplicationDetailCardActions
                rejectDialog={props.rejectDialog}
                onOfferConditionsClick={props.onOfferConditionsClick}
                loading={props.loading}
                acceptDialog={props.acceptDialog}
              />
            </Flex>
          ) : null}
        </Flex>

        {props.helperText ? (
          <Flex>
            <ApplicationDetailCardHelper
              text={props.helperText}
              intent={intent as React.ComponentProps<typeof ApplicationDetailCardHelper>['intent']}
            />
          </Flex>
        ) : null}
      </Flex>
    </Card>
  )
}
