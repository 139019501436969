import { SimpleResponse } from 'schools-domain-backend-utils'
import { ApplicationState, ProgramIntake } from 'applications-types-lib'

import {
  VersionedApplicationSanitized,
  DecisionTypeEnum,
  StudentResponseEnum,
} from '@backend/sanitize/application'

import { ApiStudentRequest, request } from '../utils/request'
import { useAuthMutation } from './auth'

type UpdateDecisionLettersParams = {
  applicationId: string
  studentDecisions: {
    [key: string]: {
      letterType: DecisionTypeEnum
      studentResponse: StudentResponseEnum
      reason?: string
    }
  }
}

export type RawApplicationsResponse = SimpleResponse<{
  id: string
  type: 'application_aggregation'
  attributes: {
    application: VersionedApplicationSanitized
    deferralOptions: {
      availableIntakes: Array<ProgramIntake>
      helperText: string
      reasonsForDeferral: string[]
      deferralState: ApplicationState
    } | null
    school: {
      additionalDocuments: Array<{
        hash: string
        fileName: string
        url: string
        uploadedAt: string
      }>
    }
  }
}>

export function useUpdateDecisionLetters() {
  const { isPending, mutate, data } = useAuthMutation(
    async (params: UpdateDecisionLettersParams) => {
      return await request<RawApplicationsResponse>(
        new ApiStudentRequest(`/applications/${params.applicationId}/decisions`, {
          method: 'POST',
          body: {
            data: {
              id: params.applicationId,
              type: 'decisions',
              attributes: {
                studentDecisions: params.studentDecisions,
              },
            },
          },
        }),
        {
          isExpectedResponse: (res): res is RawApplicationsResponse => res,
        },
      )
    },
  )

  return {
    isLoadingUpdateLettersDecision: isPending,
    updateLettersDecision: mutate,
    application: data?.data || null,
  }
}
